import { SERVER_URL } from "../../utils/server/server";
import { mainFetcher } from "../../utils/services/service";

export const getUsers = async (search, publicProfile, pageNo , pageSize, sortField, sortDir) => {
    publicProfile = publicProfile !== null ? '&publicProfile=' + publicProfile : ''
    
    const urlParams = 'pageNo=' + pageNo 
    +  '&pageSize=' + pageSize
    + '&sortField=' + sortField
    + '&sortDir=' + sortDir
    + '&search=' + search
    + publicProfile
    return mainFetcher('get', {}, SERVER_URL + '/api/users/?' + urlParams )
};

export const createUser = async (user) => {
    return mainFetcher('post', user, SERVER_URL + '/api/users/')
};

export const updateUser = async (user) => {
    return mainFetcher('put', user, SERVER_URL + '/api/users/')
};

export const getUserById = async (userId) => {
    return mainFetcher('get', {}, SERVER_URL + '/api/users/' + userId)
};

export const deleteUserById = async (userId) => {
    return mainFetcher('delete', {}, SERVER_URL + '/api/users/' + userId)
};

export const getUserByUsername = async (username, publicProfile) => {
    publicProfile = publicProfile !== null ? 'publicProfile=' + publicProfile : ''
    return mainFetcher('get', {}, SERVER_URL + '/api/users/get-by-username/'+ username + '?' + publicProfile)
};

export const getCurrentUserProfile = async (username) => {
    return mainFetcher('get', {}, SERVER_URL + '/api/users/get-current-user-profile/'+ username)
};

export const getUserByEmail = async (email, publicProfile) => {
    publicProfile = publicProfile !== null ? 'publicProfile=' + publicProfile : ''
    return mainFetcher('get', {}, SERVER_URL + '/api/users/get-by-email/'+ email + '?' + publicProfile)
};

export const userExistsByEmail = async (email) => {

    return mainFetcher('get', {}, SERVER_URL + '/api/users/exists-by-email/'+ email)
};

export const userExistsByUsername = async (username) => {
    return mainFetcher('get', {}, SERVER_URL + '/api/users/exists-by-username/'+ username)
};

export const userExistsByMobile = async (mobile) => {
    return mainFetcher('get', {}, SERVER_URL + '/api/users/exists-by-mobile/'+ mobile)
};

export const getUserCount = async () => {
    return mainFetcher('get', {}, SERVER_URL + '/api/users/count')
};

export const checkHasAnyPrivileges = async (userId, privileges) => {
    return mainFetcher('post', privileges, SERVER_URL + '/api/users/'+ userId + '/check-any-privileges')
};

export const setFollowPolicy = async (followPolicyDataSettings) => {
    return mainFetcher('put', followPolicyDataSettings, SERVER_URL + '/api/users/'+ followPolicyDataSettings.userId + '/follow-policy')
};

export const getFollowPolicy = async (userId) => {
    return mainFetcher('get', {}, SERVER_URL + '/api/users/'+ userId + '/follow-policy')
};